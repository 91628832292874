import image3 from "../../assets/images/undraw_professor_re_mj1s.svg";

export const homeObjThree = {
  lightBg: false,
  imgStart: "",
  lightText: true,
  lightTopLine: true,
  topLine: "marketing agency",
  description:
    "We help business owners increase their revenue. Our Team of unique specialist can help you achieve your business goals",
  primary: true,
  lightTextDesc: true,
  headline: "lead generation specialist for online business",
  buttonLabel: "Get started",
  start: "",
  alt: "Image",
  img: image3,
};
